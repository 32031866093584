
import React from "react";
import styles from './Card.module.scss';

const Card = ({ name, colour=null, link=null, image=null, long=false }) => {

  const longClass = long ? styles['card__long'] : ''
  const cardClasses = styles['card'] + ` ${longClass}`

  const background = {
    'background': colour ? colour : '',
    'background-image': link ? `url(${image})` : ''
  };

  return (
    <div
      className={cardClasses}
      style={background}
    >
      <a
        className={styles['card__link']}
        href={link}
      >
      </a>
    </div>
  );
};

export default Card;
