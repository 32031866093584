
import React from "react";
import styles from './Banner.module.scss';

const Banner = ({ name, image, link=null }) => {

  return (
    <div
      className={styles['banner']}
    >
      <a
        className={styles['banner__link']}
        href={link}
      >
        <img
          src={image}
          alt={name}
        />
      </a>
    </div>
  );
};

export default Banner;
