  
import React from "react"
import {Helmet} from "react-helmet";
import styles from './index.module.scss';
import Card from "../components/Card/Card";
import Banner from "../components/Banner/Banner";


const Main = () => {

  return (
    <div className={styles['main']}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mathew Payne</title>
        <link rel="canonical" href="https://mathewpayne.dev" />
        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "Article",
            "name": "Mathew Payne",
            "author": {
                "@type": "Person",
                "name": "Mathew Payne"
            },
            "image": "https://mathewpayne.dev/media/banner-geekmasher_dev.png",
            "itemListElement": [
                "Hello there",
                "Mathew Calling",
                "GeekMasher's Oat Milk" 
            ],
            "articleBody": "Mathew Website"
        }
        `}</script>
      </Helmet>


      {/* <Title /> */}
      <Banner
        name="GeekMasher's Blog"
        image="/media/banner-geekmasher_dev.png"
        link="https://geekmasher.dev"
      />

      <section className={styles['main__grid']}>
        {/* https://www.flaticon.com/packs/social-networks-logos-2 */}
        <Card
          name="Twitter"
          link="https://twitter.com/geekmasher"
          image="/media/twitter.svg"
        />
        <Card
          name="LinkedIn"
          link="https://www.linkedin.com/in/mathewbpayne/"
          image="/media/linkedin.svg"
        />
        
        <Card
          name="YouTube"
          link="https://www.youtube.com/c/GeekMasher"
          image="/media/youtube.svg"
        />
        <Card
          name="Instagram"
          link="https://www.instagram.com/geekmasher/"
          image="/media/instagram.svg"
        />
        <Card
          name="Twitch"
          link="http://twitch.tv/geekmasher"
          image="/media/twitch.svg"
        />
        <Card
          name="Facebook"
          link="https://www.facebook.com/GeekMasher"
          image="/media/facebook.svg"
        />
      </section>

      {/* <Footer /> */}
    </div>
  )
}

export default Main;
